import Auth from '@aws-amplify/auth';
import { get } from 'lodash';
import CustomSessionStorage from './components/Session/SessionStorage';
import {
    API_ENDPOINT_DEV,
    API_ENDPOINT_NONPROD,
    API_ENDPOINT_PRETEST,
    API_ENDPOINT_PROD,
    API_ENDPOINT_TEST,
    API_ENDPOINT_UAT,
    API_NAME,
    APPSYNC_ENDPOINT_DEV,
    APPSYNC_ENDPOINT_NONPROD,
    APPSYNC_ENDPOINT_PRETEST,
    APPSYNC_ENDPOINT_PROD,
    APPSYNC_ENDPOINT_TEST,
    APPSYNC_ENDPOINT_UAT,
    AWS_APPSYNC_AUTH_TYPE_API_KEY,
    AWS_APPSYNC_AUTH_TYPE_COGNITO,
    AWS_REGION_SE_2,
    SYSTEM_ENDPOINT_DEV,
    SYSTEM_ENDPOINT_NONPROD,
    SYSTEM_ENDPOINT_PRETEST,
    SYSTEM_ENDPOINT_PROD,
    SYSTEM_ENDPOINT_TEST,
    SYSTEM_ENDPOINT_UAT,
    USERPOOL_ID_DEV,
    USERPOOL_ID_NONPROD,
    USERPOOL_ID_PRETEST,
    USERPOOL_ID_PROD,
    USERPOOL_ID_TEST,
    USERPOOL_ID_UAT,
    USERPOOL_WEBCLIENT_ID_DEV,
    USERPOOL_WEBCLIENT_ID_NONPROD,
    USERPOOL_WEBCLIENT_ID_PRETEST,
    USERPOOL_WEBCLIENT_ID_PROD,
    USERPOOL_WEBCLIENT_ID_TEST,
    USERPOOL_WEBCLIENT_ID_UAT,
} from './config/config';

export enum Environments {
    DEV = 'dev',
    PROD = 'prod',
    TEST = 'test',
    PRETEST = 'pretest',
    UAT = 'uat',
    NONPROD = 'nonprod',
}

const customGraphQLHeader = async () => {
    try {
        const currentSession = await Auth.currentSession();
        // const token = get(currentSession, 'idToken.jwtToken');
        const token = get(currentSession, 'accessToken.jwtToken');
        return { Authorization: token };
    } catch (e) {
        console.error(e);
        return {};
        // Potentially you can retrieve it from local storage
    }
};

const customAPIHeader = async () => {
    try {
        const currentSession = await Auth.currentSession();
        // const token = get(currentSession, 'idToken.jwtToken');
        const token = get(currentSession, 'accessToken.jwtToken');
        return { Authorization: token };
    } catch (e) {
        console.error(e);
        return {};
    }
};

const getRedirectUrl = (env: string) => {
    if (env === Environments.DEV) {
        if (
            window.location.hostname === 'localhost' ||
            window.location.hostname === '127.0.0.1'
        ) {
            return `http://${window.location.hostname}:3000`;
        } else {
            return `https://support.${SYSTEM_ENDPOINT_DEV}`;
        }
    } else if (env === Environments.TEST) {
        return `https://support.${SYSTEM_ENDPOINT_TEST}`;
    } else if (env === Environments.PRETEST) {
        return `https://support.${SYSTEM_ENDPOINT_PRETEST}`;        
    } else if (env === Environments.UAT) {
        return `https://support.${SYSTEM_ENDPOINT_UAT}`;
    } else if (env === Environments.NONPROD) {
        return `https://support.${SYSTEM_ENDPOINT_NONPROD}`;
    } else if (env === Environments.PROD) {
        return `https://support.${SYSTEM_ENDPOINT_PROD}`;
    }
};

const AMPLIFY_CONFIG_DEV = {
    environment: Environments.DEV,
    aws_appsync_graphqlEndpoint: APPSYNC_ENDPOINT_DEV,
    aws_appsync_region: AWS_REGION_SE_2,
    aws_appsync_authenticationType: AWS_APPSYNC_AUTH_TYPE_COGNITO,
    // aws_appsync_apiKey: 'da2-uak646xx55bupiflysxwmwjaqi',
    Auth: {
        // mandatorySignIn: true,
        region: AWS_REGION_SE_2,
        userPoolId: USERPOOL_ID_DEV,
        userPoolWebClientId: USERPOOL_WEBCLIENT_ID_DEV,
        storage: CustomSessionStorage,
        oauth: {
            domain: 'iodm-support-so-dev.auth.ap-southeast-2.amazoncognito.com',
            scope: ['aws.cognito.signin.user.admin', 'email', 'openid'],
            redirectSignIn: getRedirectUrl(Environments.DEV),
            redirectSignOut: getRedirectUrl(Environments.DEV),
            responseType: 'token',
            client_id: USERPOOL_WEBCLIENT_ID_DEV,
        },
    },
    API: {
        graphql_headers: customGraphQLHeader,
        endpoints: [
            {
                name: API_NAME,
                endpoint: API_ENDPOINT_DEV,
                custom_header: customAPIHeader,
            },
        ],
    },
};

// eslint-disable-next-line
const AMPLIFY_CONFIG_TEST = {
    environment: Environments.TEST,
    aws_appsync_graphqlEndpoint: APPSYNC_ENDPOINT_TEST,
    aws_appsync_region: AWS_REGION_SE_2,
    aws_appsync_authenticationType: AWS_APPSYNC_AUTH_TYPE_COGNITO,
    // aws_appsync_apiKey: 'da2-i5eabhmz6bcypfxuijx6wlxqru',
    Auth: {
        mandatorySignIn: true,
        region: AWS_REGION_SE_2,
        userPoolId: USERPOOL_ID_TEST,
        userPoolWebClientId: USERPOOL_WEBCLIENT_ID_TEST,
        storage: CustomSessionStorage,
        oauth: {
            domain:
                'iodm-support-so-test.auth.ap-southeast-2.amazoncognito.com',
            scope: ['aws.cognito.signin.user.admin', 'email', 'openid'],
            redirectSignIn: getRedirectUrl(Environments.TEST),
            redirectSignOut: getRedirectUrl(Environments.TEST),
            responseType: 'token',
            client_id: USERPOOL_WEBCLIENT_ID_TEST,
        },
    },
    API: {
        endpoints: [
            {
                name: API_NAME,
                endpoint: API_ENDPOINT_TEST,
                custom_header: customAPIHeader,
            },
        ],
    },
};

// eslint-disable-next-line
const AMPLIFY_CONFIG_PROD = {
    environment: Environments.PROD,
    aws_appsync_graphqlEndpoint: APPSYNC_ENDPOINT_PROD,
    aws_appsync_region: AWS_REGION_SE_2,
    aws_appsync_authenticationType: AWS_APPSYNC_AUTH_TYPE_COGNITO,
    // aws_appsync_apiKey: 'da2-i5eabhmz6bcypfxuijx6wlxqru',
    Auth: {
        mandatorySignIn: true,
        region: AWS_REGION_SE_2,
        userPoolId: USERPOOL_ID_PROD,
        userPoolWebClientId: USERPOOL_WEBCLIENT_ID_PROD,
        storage: CustomSessionStorage,
        oauth: {
            domain:
                'iodm-support-so-prod.auth.ap-southeast-2.amazoncognito.com',
            scope: ['aws.cognito.signin.user.admin', 'email', 'openid'],
            redirectSignIn: getRedirectUrl(Environments.PROD),
            redirectSignOut: getRedirectUrl(Environments.PROD),
            responseType: 'token',
            client_id: USERPOOL_WEBCLIENT_ID_PROD,
        },
    },
    API: {
        endpoints: [
            {
                name: API_NAME,
                endpoint: API_ENDPOINT_PROD,
                custom_header: customAPIHeader,
            },
        ],
    },
};

const AMPLIFY_CONFIG_UAT = {
    environment: Environments.UAT,
    aws_appsync_graphqlEndpoint: APPSYNC_ENDPOINT_UAT,
    aws_appsync_region: AWS_REGION_SE_2,
    aws_appsync_authenticationType: AWS_APPSYNC_AUTH_TYPE_COGNITO,
    Auth: {
        mandatorySignIn: true,
        region: AWS_REGION_SE_2,
        userPoolId: USERPOOL_ID_UAT,
        userPoolWebClientId: USERPOOL_WEBCLIENT_ID_UAT,
        storage: CustomSessionStorage,
        oauth: {
            domain: 'iodm-support-so-uat.auth.ap-southeast-2.amazoncognito.com',
            scope: ['aws.cognito.signin.user.admin', 'email', 'openid'],
            redirectSignIn: getRedirectUrl(Environments.UAT),
            redirectSignOut: getRedirectUrl(Environments.UAT),
            responseType: 'token',
            client_id: USERPOOL_WEBCLIENT_ID_UAT,
        },
    },
    API: {
        endpoints: [
            {
                name: API_NAME,
                endpoint: API_ENDPOINT_UAT,
                custom_header: customAPIHeader,
            },
        ],
    },
};

const AMPLIFY_CONFIG_PRETEST = {
    environment: Environments.PRETEST,
    aws_appsync_graphqlEndpoint: APPSYNC_ENDPOINT_PRETEST,
    aws_appsync_region: AWS_REGION_SE_2,
    aws_appsync_authenticationType: AWS_APPSYNC_AUTH_TYPE_COGNITO,
    Auth: {
        mandatorySignIn: true,
        region: AWS_REGION_SE_2,
        userPoolId: USERPOOL_ID_PRETEST,
        userPoolWebClientId: USERPOOL_WEBCLIENT_ID_PRETEST,
        storage: CustomSessionStorage,
        oauth: {
            domain:
                'iodm-support-so-pretest.auth.ap-southeast-2.amazoncognito.com',
            scope: ['aws.cognito.signin.user.admin', 'email', 'openid'],
            redirectSignIn: getRedirectUrl(Environments.PRETEST),
            redirectSignOut: getRedirectUrl(Environments.PRETEST),
            responseType: 'token',
            client_id: USERPOOL_WEBCLIENT_ID_PRETEST,
        },
    },
    API: {
        endpoints: [
            {
                name: API_NAME,
                endpoint: API_ENDPOINT_PRETEST,
                custom_header: customAPIHeader,
            },
        ],
    },
};

const AMPLIFY_CONFIG_NONPROD = {
    environment: Environments.NONPROD,
    aws_appsync_graphqlEndpoint: APPSYNC_ENDPOINT_NONPROD,
    aws_appsync_region: AWS_REGION_SE_2,
    aws_appsync_authenticationType: AWS_APPSYNC_AUTH_TYPE_COGNITO,
    Auth: {
        mandatorySignIn: true,
        region: AWS_REGION_SE_2,
        userPoolId: USERPOOL_ID_NONPROD,
        userPoolWebClientId: USERPOOL_WEBCLIENT_ID_NONPROD,
        storage: CustomSessionStorage,
        oauth: {
            domain:
                'iodm-support-so-nonprod.auth.ap-southeast-2.amazoncognito.com',
            scope: ['aws.cognito.signin.user.admin', 'email', 'openid'],
            redirectSignIn: getRedirectUrl(Environments.NONPROD),
            redirectSignOut: getRedirectUrl(Environments.NONPROD),
            responseType: 'token',
            client_id: USERPOOL_WEBCLIENT_ID_NONPROD,
        },
    },
    API: {
        endpoints: [
            {
                name: API_NAME,
                endpoint: API_ENDPOINT_NONPROD,
                custom_header: customAPIHeader,
            },
        ],
    },
};

/**
 * Public API configs
 */

export const AMPLIFY_PUBLIC_CONFIG_DEV = {
    aws_appsync_graphqlEndpoint: APPSYNC_ENDPOINT_DEV,
    aws_appsync_region: AWS_REGION_SE_2,
    aws_appsync_authenticationType: AWS_APPSYNC_AUTH_TYPE_API_KEY,
    // aws_appsync_apiKey: 'da2-ryew6ojqzjcn5layh3li4sbwva',
    aws_appsync_apiKey: 'da2-4snbnmrvaraaxbi2a225dlowhy',
    API: {
        graphql_headers: customGraphQLHeader,
        endpoints: [
            {
                name: API_NAME,
                endpoint: API_ENDPOINT_DEV,
                custom_header: customAPIHeader,
            },
        ],
    },
};

export const AMPLIFY_PUBLIC_CONFIG_TEST = {
    aws_appsync_graphqlEndpoint: APPSYNC_ENDPOINT_TEST,
    aws_appsync_region: AWS_REGION_SE_2,
    aws_appsync_authenticationType: AWS_APPSYNC_AUTH_TYPE_API_KEY,
    aws_appsync_apiKey: 'da2-76h7leap6vdo3dkjzqd6vx7yvi',
    API: {
        graphql_headers: customGraphQLHeader,
        endpoints: [
            {
                name: API_NAME,
                endpoint: API_ENDPOINT_TEST,
                custom_header: customAPIHeader,
            },
        ],
    },
};

export const AMPLIFY_PUBLIC_CONFIG_UAT = {
    aws_appsync_graphqlEndpoint: APPSYNC_ENDPOINT_UAT,
    aws_appsync_region: AWS_REGION_SE_2,
    aws_appsync_authenticationType: AWS_APPSYNC_AUTH_TYPE_API_KEY,
    aws_appsync_apiKey: 'da2-redmah7shbdktbwx2swkl6k6qa',
    API: {
        graphql_headers: customGraphQLHeader,
        endpoints: [
            {
                name: API_NAME,
                endpoint: API_ENDPOINT_UAT,
                custom_header: customAPIHeader,
            },
        ],
    },
};

export const AMPLIFY_PUBLIC_CONFIG_PRETEST = {
    aws_appsync_graphqlEndpoint: APPSYNC_ENDPOINT_PRETEST,
    aws_appsync_region: AWS_REGION_SE_2,
    aws_appsync_authenticationType: AWS_APPSYNC_AUTH_TYPE_API_KEY,
    aws_appsync_apiKey: 'da2-hg3ykejqaneftegrnalmx7oszi',
    API: {
        graphql_headers: customGraphQLHeader,
        endpoints: [
            {
                name: API_NAME,
                endpoint: API_ENDPOINT_PRETEST,
                custom_header: customAPIHeader,
            },
        ],
    },
};

export const AMPLIFY_PUBLIC_CONFIG_NONPROD = {
    aws_appsync_graphqlEndpoint: APPSYNC_ENDPOINT_NONPROD,
    aws_appsync_region: AWS_REGION_SE_2,
    aws_appsync_authenticationType: AWS_APPSYNC_AUTH_TYPE_API_KEY,
    aws_appsync_apiKey: 'da2-urzgcsehojhrplmbmpapqwmadq',
    API: {
        graphql_headers: customGraphQLHeader,
        endpoints: [
            {
                name: API_NAME,
                endpoint: API_ENDPOINT_NONPROD,
                custom_header: customAPIHeader,
            },
        ],
    },
};

export const AMPLIFY_PUBLIC_CONFIG_PROD = {
    aws_appsync_graphqlEndpoint: APPSYNC_ENDPOINT_PROD,
    aws_appsync_region: AWS_REGION_SE_2,
    aws_appsync_authenticationType: AWS_APPSYNC_AUTH_TYPE_API_KEY,
    aws_appsync_apiKey: 'da2-g2zfdd442bfo5mjm6q7epig5om',
    API: {
        graphql_headers: customGraphQLHeader,
        endpoints: [
            {
                name: API_NAME,
                endpoint: API_ENDPOINT_PROD,
                custom_header: customAPIHeader,
            },
        ],
    },
};

let defaultConfig: any = AMPLIFY_CONFIG_DEV; // defaults to AMPLIFY_CONFIG_DEV if REACT_APP_ENV is not defined

if (process.env.REACT_APP_STAGE === Environments.DEV) {
    defaultConfig = AMPLIFY_CONFIG_DEV;
} else if (process.env.REACT_APP_STAGE === Environments.PROD) {
    defaultConfig = AMPLIFY_CONFIG_PROD;
} else if (process.env.REACT_APP_STAGE === Environments.TEST) {
    defaultConfig = AMPLIFY_CONFIG_TEST;
} else if (process.env.REACT_APP_STAGE === Environments.PRETEST) {
    defaultConfig = AMPLIFY_CONFIG_PRETEST;    
} else if (process.env.REACT_APP_STAGE === Environments.UAT) {
    defaultConfig = AMPLIFY_CONFIG_UAT;
} else if (process.env.REACT_APP_STAGE === Environments.NONPROD) {
    defaultConfig = AMPLIFY_CONFIG_NONPROD;
}

export default defaultConfig;
